import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import $ from 'jquery'
import axios from "axios";
import JsonExcel from 'vue-json-excel'
import vSelect from 'vue-select'
import moment from "moment";

Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)


export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin
    },
    data: function() {
        return {
            pageTitle: this.$t('facture_reglement_historique.page_titre'),
            point_de_ventes_id: "",
            montant_total: "0",
            listeTypeDepense: [],
            vuetable: {
                moreParams: {},
                fields: [
                    // {
                    //     name: VuetableFieldCheckbox,
                    //     titleClass: 'text-center',
                    //     dataClass: 'text-center'
                    // },
                    {
                        name: 'date_reglement_formatted',
                        title: this.$t('facture_reglement_historique.date_reglement'),
                        sortField: 'date_reglement',
                        dataClass: 'text-center',
                        width: "120px",
                        titleClass: "text-center"
                    },
                    {
                        name: 'nom_client',
                        title: this.$t('facture_reglement_historique.nom_client'),
                        sortField: 'nom_client',
                        dataClass: 'text-left',
                    },
                    {
                        name: 'num_facture',
                        title: this.$t('facture_reglement_historique.num_facture'),
                        sortField: 'num_facture',
                        dataClass: 'text-left text-center',
                        width: "120px",
                        titleClass: "text-center"
                    },
                    {
                        name: 'type_facture',
                        title: this.$t('facture_reglement_historique.type_facture'),
                        sortField: 'type_facture',
                        dataClass: 'text-center text-bold',
                        titleClass: "text-center",
                        width: "150px",
                    },
                    {
                        name: 'montant',
                        title: this.$t('facture_reglement_historique.montant'),
                        sortField: 'montant',
                        dataClass: 'text-right text-bold text-success',
                        titleClass: "text-center",
                        width: "150px"
                    },
                    {
                        name: 'date_echeance',
                        title: this.$t('facture_reglement_historique.date_echeance'),
                        sortField: 'date_echeance',
                        titleClass: "text-center",
                        dataClass: 'text-left text-center'
                    },
                    {
                        name: 'date_heure_enregistrement',
                        title: this.$t('facture_reglement_historique.date_heure_enregistrement'),
                        sortField: 'date_heure_enregistrement',
                        dataClass: 'text-center',
                        titleClass: "text-center",
                        width: "150px"
                    }

                ],
                sortOrder: [
                    { field: 'id', direction: 'desc' }
                ],
                css: {
                    table: {
                        tableClass: 'table table-striped table-bordered table-hovered',
                        loadingClass: 'loading',
                        ascendingIcon: 'fas fa-arrow-up fa-sm',
                        descendingIcon: 'fas fa-arrow-down fa-sm',
                        handleIcon: 'fas fa-bars',
                    },
                    pagination: {
                        infoClass: 'pull-left ',
                        wrapperClass: 'vuetable-pagination text-center',
                        activeClass: 'btn-secondary',
                        disabledClass: 'disabled',
                        pageClass: 'btn btn-border',
                        linkClass: 'btn btn-border',
                        icons: {
                            first: '',
                            prev: '',
                            next: '',
                            last: '',
                        },
                    }
                },
            },
            exportexcel: {
                json_fields: {
                    'Date': 'date',
                    'Type dépense': 'type_depense',
                    'Béneficiaire': 'beneficiaire',
                    'Montant': 'montant',
                    'Motif': 'motif',
                    'Date enregistrement': 'date_enregistrement'
                },
                json_data: [],
                json_meta: [
                    [{
                        'key': 'charset',
                        'value': 'utf-8'
                    }]
                ],
            },
            motCle: "",
            criteriacol: "",
            crudform: {
                id: "",
                date: "",
                type_depense: "",
                beneficiaire: "",
                montant: "",
                motif: "",
                date_enregistrement: "",
                point_de_ventes_id: ""
            },
            crudmodaltitle: this.$t('traitement.depense.modal.ajouter_depense'),
            dateOptions: {
                format: 'DD/MM/YYYY',
            },
            point_de_ventes: [],
            users_id: "",
            listdata: {
                users: []
            },
            filter: "",
            filtreDate: {
                dateDebut: "",
                dateFin: ""
            },


        }
    },
    methods: {
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },
        onLoading() {
            console.log('loading... show your spinner here')
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');
        },
        setFilter() {
            this.vuetable.moreParams.filter = this.motCle;
            this.vuetable.moreParams.type_depense = this.criteriacol;
            this.vuetable.moreParams.point_de_ventes_id = this.point_de_ventes_id;
            this.vuetable.moreParams.startDate = this.filtreDate.dateDebut;
            this.vuetable.moreParams.endDate = this.filtreDate.dateFin;
            this.vuetable.moreParams.users_id = this.$parent.role_user == "VENDEUR" ? this.$parent.id_user : this.users_id;
            Vue.nextTick(() => this.$refs.vuetable.refresh());

            // var that = this;
            // axios.post(that.BASE_URL + "/depenses/fetchmontanttotal", this.vuetable.moreParams).then(function(response) {
            //     console.log(response);
            //     that.montant_total = response.data[0].montantTotal;
            // });
        },
        resetFilter() {
            this.motCle = "";
            this.criteriacol = "";
            this.vuetable.moreParams.filter = this.motCle;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        onExport() {

            this.exportexcel.json_data = this.$refs.vuetable.$data.tableData;
            $("#excel-download").trigger("click");
        },
        loadPointDeVente() {
            axios.get(this.BASE_URL + "/pointvente/getall/").then(response => {
                //console.log(response.data[0]);
                this.point_de_ventes = response.data;
            });
        },
    },
    watch: {
        
    },
    computed: {
        
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);

        this.motCle = "";
        this.criteriacol = "";

        var currentDateWithFormat = moment().format('DD/MM/YYYY');
        this.filtreDate.dateDebut = currentDateWithFormat;
        this.filtreDate.dateFin = currentDateWithFormat;
        this.loadPointDeVente();
        this.setFilter();
    },
    mounted: function() {
        // var that = this;

        /*axios.get("https://vuetable.ratiw.net/api/users").then(response => {
            this.fields = response.data.data;
        });*/

        // On change zone de texte
        /*that.moreParams.filter = 'Mot a rechercher';
            Vue.nextTick(() => that.$refs.vuetable.refresh());*/
    }
}